<template>
  <div id="app">
    <TerminalNavbar />

    <div id="container">
      <div id="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
}

#container {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  padding: 1.25rem;
}

#content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: center;
}
</style>
